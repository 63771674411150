@font-face {
  font-family: Aeonik;
  src: url("../public/fonts/Aeonik-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Aeonik;
  src: url("../public/fonts/Aeonik-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Aeonik;
  src: url("../public/fonts/Aeonik-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Aeonik;
  src: url("../public/fonts/Aeonik-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Aeonik;
  src: url("../public/fonts/Aeonik-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
