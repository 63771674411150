/* Global */
html {
  scroll-behavior: smooth;
}

/* Tooltip */
.hintTooltip {
  max-width: 300px;
  margin-left: 6px;
}

.hintTooltip .tooltip-inner {
  max-width: 300px;
  padding: .25rem .5rem;
  color: #1D2939;
  text-align: left;
  background-color: #FFFFFF;
  border-radius: .25rem;
  border: solid 1px #EAECF0;
  opacity: 0.9;

  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.hintTooltip .tooltip-arrow {
  display: none;
}

/* Popup */
.base-popup {
  min-width: 276px;
  padding: .25rem .5rem;
  color: #344054;
  text-align: left;
  background-color: #FFFFFF;
  border-radius: .25rem;
  border: solid 1px #EAECF0;
  opacity: 1;

  font-family: 'Aeonik';
  font-weight: 400;
  font-size: 14px;
}

.legalzoom-popup {
  padding: 1.25rem;
  color: #FFFFFF;
  text-align: left;
  background-color: #101828;
  border-radius: .25rem;
  border: solid 1px #101828;

  font-family: 'Aeonik';
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.16px;
}

.legalzoom-popup .popover-arrow::after {
  border-bottom-color: #101828;
}

.confirmation-popup {
  border: solid 1px #EAECF0;
}

.confirmation-popup .popover-arrow::after {
  border-bottom-color: #EAECF0;
}

/* Modals */
.modal-backdrop {
  --bs-backdrop-opacity: 1;
  background: rgba(16, 24, 40, 0.8);
}

.payment-failed-modal .modal-content {
  border-radius: 5px;
}

.base-modal .modal-content {
  border-radius: 5px;
}
